.vast-skip-button {
  display: block;
  position: absolute;
  top: 5px;
  right: 0;
  width: auto;
  background-color: #000;
  color: #aaa;
  font-size: 12px;
  font-style: italic;
  line-height: 12px;
  padding: 10px;
  z-index: 2;
}

.vast-skip-button.enabled {
  cursor: pointer;
  color: #fff;
}

.vast-skip-button.enabled:hover {
  cursor: pointer;
  background: #333;
}

.vast-blocker {
  display: block;
  position: absolute;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.vjs-default-skin .vjs-big-play-button,
.vjs-default-skin:hover .vjs-big-play-button,
.vjs-default-skin .vjs-big-play-button:focus {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: none;
  box-shadow: none;
  width: 6rem;
}

.video-player.video-js .vjs-big-play-button {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
  width: 6rem !important;
  font-size: 0 !important;
}
@media (max-width: 640px) {
  .video-player.video-js .vjs-big-play-button {
    width: 4rem !important;
  }
}

.vjs-default-skin .vjs-volume-level,
.vjs-default-skin .vjs-play-progress {
  background: linear-gradient(
    270deg,
    #ff002e -14.2%,
    rgba(217, 217, 217, 0) 149.5%
  );
}

.video-js .vjs-control-bar {
  opacity: 1 !important;
  visibility: visible;
}

.vjs-default-skin .vjs-volume-control .vjs-slider-handle:before {
  content: "•";
  font-size: 3em;
  left: -0.5em;
}
.vjs-default-skin .vjs-slider-handle:before {
  content: "•";
  font-size: 8em;
  padding-top: 0;
  top: -0.5em;
}
.video-js .vjs-big-play-button {
  margin-left: 0;
}

.vjs-default-skin .vjs-big-play-button:before {
  content: url("../../icon/circle-play-solid.svg");
}
.vjs-poster img {
  height: 100% !important;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  content: "";
}
