/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~swiper/css";
@import "~swiper/css/free-mode";
@import "~swiper/css/pagination";

@import "~swiper/scss";
@import "~swiper/scss/autoplay";
@import "~swiper/scss/keyboard";
@import "~swiper/scss/navigation";
@import "~swiper/scss/pagination";
@import "~swiper/scss/scrollbar";
@import "~swiper/scss/zoom";
@import "~@ionic/angular/css/ionic-swiper";
@import "~swiper/css/effect-cube";

//estos estilos joden el calendario colocar solo para maquetar algo de primeng y comentar de nuevo
@import "primeng/resources/themes/mdc-dark-indigo/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

@font-face {
  font-family: "Inter";
  src: local("Inter-Black") url(./assets/fonts/Inter/static/Inter-Black.ttf)
    format("truetype");
}
@font-face {
  font-family: "Inter";
  src: local("Inter-Bold"),
    url(./assets/fonts/Inter/static/Inter-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter-ExtraBold"),
    url(./assets/fonts/Inter/static/Inter-ExtraBold.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter-ExtraLight"),
    url(./assets/fonts/Inter/static/Inter-ExtraLight.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter-Light"),
    url(./assets/fonts/Inter/static/Inter-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter-Medium"),
    url(./assets/fonts/Inter/static/Inter-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter-Regular") url(./assets/fonts/Inter/static/Inter-Regular.ttf)
    format("truetype");
}
@font-face {
  font-family: "Inter";
  src: local("Inter-SemiBold")
    url(./assets/fonts/Inter/static/Inter-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter-Thin") url(./assets/fonts/Inter/static/Inter-Thin.ttf)
    format("truetype");
}

@media print {
  html,
  body {
    display: none;
  }
}
body {
  background-color: #000;
}

:root {
  /** Colores en general*/
  --color-primary: #ff4000;
  --color-gray: #8d8d8d;
  --color-dark-gray: #585858;
  --color-light-gray: #dbdbdb;
  --color-black: #000;
  --color-white: #fff;

  /** Colores para el estado*/
  --state-color-error: #fc1313;
  --state-color-warning: #fccf32;
  --state-color-done: #b2f1ff;
  --state-color-on: #ff002e;

  /** Tamaño de letras */
  --font-size-xll: 30px;
  --font-size-xl: 24px;
  --font-size-l: 20px;
  --font-size-m: 18px;
  --font-size-sm: 16px;
  --font-size-xsm: 12px;

  /** Grosor del texto */
  --font-bold: 700;
  --font-semibold: 500;

  /** Color del texto*/
  --text-color: var(--color-black);
  --text-color-hover: var(--color-white);
}

*,
body,
html,
button {
  font-family: "Inter",sans-serif;
  color: #ffff;
}

.color {
  &-white {
    color: #ffff !important;
    --color: #ffff !important;
  }
  &-primary {
    color: #b81858;
  }
  &-secondary {
    color: #17edd380;
  }
}

ion-button {
  --background: linear-gradient(
    0deg,
    rgba(176, 24, 88, 0) 0%,
    rgba(176, 24, 88, 1) 100%
  );
  --border-radius: 40px;
  font-size: 18px;
  --padding-top: 1.2rem;
  --padding-bottom: 1.2rem;
  height: fit-content;
  border: solid 1px #000;
  font-family: "inter-medium";
  font-weight: 600;
  letter-spacing: 0.4px;
  border-radius: 40px;
  text-transform: capitalize;
}
ion-button.button-secondary {
  --background: transparent;
  border: solid 1px #17edd380;
  border-radius: 40px;
  font-family: "inter-medium";
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.4px;
  text-transform: capitalize;
}
ion-button.subscripters {
  --background: transparent;
  border: solid 1px #17edd380;
  border-radius: 40px;
  font-family: "inter-medium";
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  --padding-top: 3px;
  --padding-bottom: 3px;
  --padding-start: 3px;
  --padding-end: 3px;
  ion-icon {
    color: #17edd380;
  }
}
ion-button.button-profile {
  width: 47px;
  margin: 12px 10px 0px 10px;
  --background: rgb(172, 172, 7);
  border: none;
  border-radius: 40px;
  font-family: "inter-medium";
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.4px;
  text-transform: capitalize;
  --padding-top: 3px;
  --padding-bottom: 3px;
  --padding-start: 3px;
  --padding-end: 3px;
}
ion-item.transparent {
  --background: transparent;
}
ion-datetime {
  --background: #0000003b;
  --title-color: #fff;
  .datetime-year-body.order-month-first {
    background-color: #b81858;
  }
}

ion-calendar ion-picker-internal {
  background-color: red;
  color: blue;
}

/*home*/
ion-searchbar.home {
  -webkit-padding-start: 12px;
  padding-inline-start: 12px;
  -webkit-padding-end: 0px;
  padding-inline-end: 0px;
  --background: #a4a4a4;
  --placeholder-color: #6a6969;
  --icon-color: #6a6969;
  padding-bottom: 0;

  .searchbar-input,
  .searchbar-clear-icon {
    color: #6a6969;
    border-radius: 40px;
    padding-top: 3px;
    padding-bottom: 1px;
  }
}

ion-searchbar.recommendation-searchbar {
  width: 90%;
  --background: #ffff;
  --color: #000;
  --placeholder-color: #000;
  --border-radius: 20px;
}

ion-textarea {
  .native-textarea {
    color: #ffff !important;
  }
}
ion-textarea.publication {
  .native-textarea {
    height: 45px;
    color: #8d8d8d !important;
  }
}

ion-textarea.comment-input {
  border-bottom: 1px solid #fff !important;
}

ion-textarea.one-line .textarea-wrapper .native-textarea {
  position: relative;
  top: 10px;
  @media (max-width: 640px) {
    top: 8px;
  }
}
/*ion-textarea {
  .native-textarea {
    color: gray !important;
  }
}*/
.publication-input ion-textarea {
  .native-textarea {
    color: #ffff !important;
  }
}
.publication-input.state-modal-footer ion-textarea {
  .native-textarea {
    color: #fff !important;
  }
}
ion-header.home {
  background: #828181;
  display: flex;
  flex-direction: column;
}
.flex {
  display: flex;
  align-items: center;
}
.text-aling-end {
  text-align: end !important;
}
.collapsable {
  max-height: 1200px;
  height: fit-content;
  visibility: inherit;
  opacity: 1;
  transition: all ease-in-out 0.5s;
}

.collapsable.hidden {
  max-height: 0px;
  height: 0;
  visibility: collapse;
  opacity: 0;
  transition: all ease-in-out 0.2s;
}

ion-action-sheet {
  .action-sheet-group.sc-ion-action-sheet-ios {
    background: black;
  }
  .action-sheet-button.sc-ion-action-sheet-ios {
    background: #666666;
  }
  .action-sheet-selected.sc-ion-action-sheet-ios::after {
    background: #565656;
  }
  .action-sheet-cancel.sc-ion-action-sheet-ios::after {
    background: #666666;
  }

  .action-sheet-group.sc-ion-action-sheet-md {
    background: black;
  }
  .action-sheet-button.sc-ion-action-sheet-md {
    background: #666666;
  }
  .action-sheet-selected.sc-ion-action-sheet-md::after {
    background: #565656;
  }
  .action-sheet-cancel.sc-ion-action-sheet-md::after {
    background: #666666;
  }
}
ion-loading.custom-loading {
  .loading-wrapper {
    //display: none;
    background: transparent;
  }
}

ion-toast.custom-toast {
  --background: #3c3c3c;
  --box-shadow: 3px 3px 10px 0 rgba(0, 0, 0, 0.2);
  --border: none
  --color: #666666;
}
ion-toast.error-toast {
  --background: #1e1e1e;
  --border-radius: 22px;
  --border-width: 2px;
  --max-width: 400px;
}

ion-toast.error-toast::part(message) {
  font-weight: 500;
  font-size: 15px;
}
ion-toast.error-toast::part(icon) {
  color: #730cf5;
  font-size: 28px;
}

ion-toast.send-toast {
  --background: #3c3c3c;
  --border-radius: 22px;
  --border-width: 2px;
  --max-width: 400px;
}

ion-toast.send-toast::part(message) {
  font-weight: 500;
  font-size: 15px;
}
ion-toast.send-toast::part(icon) {
  color: #ffffff;
  font-size: 28px;
}

ion-toast.check-toast {
  --background: #3c3c3c;
  --border:none;
  --border-radius: 22px;
  --border-width: 2px;
  --max-width: 400px;
}

ion-toast.check-toast::part(message) {
  font-weight: 500;
  font-size: 15px;
}
ion-toast.check-toast::part(icon) {
  color: #730cf5;
  font-size: 28px;
}

.swiper .swiper-slide {
  margin: auto;
  min-width: 200px;
}
.swiper .swiper-slide .new-publication {
  min-width: 200px;
}

//cambios para swipper folder
.swiper.swiper-folder .swiper-slide {
  margin: initial;
  min-width: initial;
}
.swiper.swiper-folder .swiper-button-next,
.swiper-button-prev {
  width: 25px;
  height: 25px;
  border: 2px solid #343434;
  border-radius: 100%;
  background: #343434;
}

.swiper.swiper-select-profile .swiper-slide {
  margin: initial;
  min-width: initial;
  align-items: flex-start;
  justify-content: flex-start;
}

.swiper.swiper-select-profile .swiper-button-next,
.swiper-button-prev {
  width: 20px;
  height: 20px;
  border: 2px solid #343434;
  border-radius: 100%;
}
.swiper.swiper-select-profile .swiper-slide img {
  border-radius: 50%;
  width: 60px;
  height: 60px;
}
.swiper.swiper-select-profile .swiper-button-next:after,
.swiper-button-prev:after {
  color: #b7b7b7;
  font-size: 9px;
}

.swiper.swiper-folder .swiper-button-next:after,
.swiper-button-prev:after {
  color: #b7b7b7;
  font-size: 12px;
}

.gallery:not(.gallery-uniform) {
  @media (max-width: 820px) {
    .swiper .swiper-slide {
      min-width: 90vw !important;
      height: 100%;
    }
  }
}
.mySwiper.swiper-profile-gallery {
  .swiper-slide {
    margin: initial;
    transition: all ease-in-out 0.3s;
  }
  @media (max-width: 820px) {
    max-height: 1020px;
    .swiper-slide {
      visibility: hidden;
      opacity: 0;
    }
    .swiper-slide.swiper-slide-active {
      visibility: visible;
      opacity: 1;
    }
  }
}

.mySwiper.plastt {
  height: 200px;
}

.mySwiper.detail-image-folder.swiper {
  width: 100%;
  //max-width: 100%;

  img {
    width: auto;
    max-width: 100%;
    height: 79vh;
    max-height: 100%;
    object-fit: contain;
  }

  img.img-video {
    width: 120px;
    height: 120px;
    border: 4px solid #ff002e;
    padding: 4px;
    border-radius: 100%;
    @media (max-width: 640px) {
      width: 90px;
      height: 90px;
    }
  }

  .swiper-wrapper {
    width: 100%;
    height: calc(90dvh - 100px);
    //max-width: 21vw;
  }

  .swiper-slide {
    margin: auto;
    // min-width: 200px;

    max-height: calc(120dvh - 120px);
    img,
    video {
      //aqui coloque cambio
      // @media (max-width: 900px) {
      //   width: 100vw !important;
      // }
      // //width: 21vw;
      // width: 100vw;
    }
  }
}

.mySwiper.detail-image.swiper {
     width: 100%;
    height: 100dvh;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  .swiper-wrapper {
    width: 100%;
    //max-width: 21vw;
  }

  .swiper-slide {
    margin: auto;
    // min-width: 200px;

    max-height: calc(100dvh - 100px);
    img,
    video {
      //aqui coloque cambio
      // @media (max-width: 900px) {
      //   width: 100vw !important;
      // }
      // //width: 21vw;
      // width: 100vw;
    }
  }
}
.mySwiper.mobile {
  @media (min-width: 300px) and (max-width: 375px) {
    height: 89dvh;
  }
  height: 75dvh;
}
.swiper-cube .swiper-cube-shadow {
  display: none;
}
.mySwiper.detail-image.one-image.swiper {
  .swiper-slide {
    //aqui coloque cambio
    // @media (max-width: 900px) {
    //   width: 100vw !important;
    // }

    // width: 23vw !important;
    // height: 81vh !important;
  }
}
.mySwiper.gallery-uniform {
 height:100%;
  // height: 100dvh;
  width: 100%;
}
.mySwiper.new-publication {
  height: 100%;
  width: 100%;
}

ion-searchbar.recommendation-searchbar {
  width: 96%;
  --background: transparent;
  --color: #ffffff;
  --placeholder-color: #ffffff;
  padding-inline-end: 0px;
  padding-inline-start: 0px;
  padding-top: 0px;
  padding-bottom: 0px;

  border-radius: 22px;
  border: 1px solid #000;
  background: #323233;
  height: 43px;

  /*padding-top: 18px;
  padding-bottom: 21px;*/
  // max-width: 350px;
  .searchbar-input-container {
    color: #ffffff !important;
    height: 45px;
  }

  .searchbar-search-icon {
    right: 16px !important;
    left: initial !important;
    //display: none !important;
  }
  .searchbar-search-icon,
  .searchbar-clear-icon {
    color: #8d8d8d !important;
    @media (max-width: 640px) {
      width: 15px;
      right: 10px !important;
    }
  }
  .searchbar-input {
    padding-inline-start: 45px !important;
    color: #8d8d8d;
    font-family: Inter;
    font-size: 15px;
    font-style: italic;
    font-weight: 600;
    padding-left: 15px !important;
    @media (max-width: 820px) {
      padding-inline-start: 10px !important;
      height: 97%;
      font-size: 15px;
    }
    @media (max-width: 640px) {
      font-size: 12px;
    }
  }
}

ion-searchbar.recommendation-searchbar.searchbar-has-value {
  .searchbar-search-icon {
    display: none !important;
  }
}

.folder-slides {
  .swiper-slide.swiper-slide-visible {
    border-radius: 12px;
  }
  .swiper-slide.swiper-slide-visible.swiper-slide-active {
    border: 1px solid #ff002e;
  }
  .swiper-pagination.swiper-pagination-fraction.swiper-pagination-horizontal {
    font-weight: 600;
    color: black;
    span {
      font-weight: 600;
      color: black !important;
    }
  }
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: 50px;
}

.swiper .swiper-pagination-bullet {
   width: 4px;
  height: 4px;
  background: #fff;
}
.swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 4px;
  height: 4px;
  background: #fff;
}

/** remove buttons input number*/
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.swiper-button-next,
.swiper-button-prev {
    color: #fff; /* Cambia el color a morado */
    border-radius: 50%; /* Si quieres que el botón sea circular */
    width: 50px; /* Ajusta el tamaño */
    height: 50px; /* Ajusta el tamaño */
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-size: 25px;
      background: none !important; /* Elimina cualquier fondo aplicado */
    border: none; /* Asegúrate de que no haya bordes visibles */
    box-shadow: none; /* Elimina cualquier sombra */

}

.swiper-button-next:hover,
.swiper-button-prev:hover {
    background-color: transparent; /* Cambia el fondo al pasar el cursor */
}



.swiper-button-next::after,
.swiper-button-prev::after {
  background-color: transparent;
      font-weight: bold;

    color: #fff !important; /* Cambia el color a morado */
      font-size: 18px; /* Ajusta el tamaño del ícono de la flecha */
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

/** buttons*/
.btn {
  width: 100%;
  height: fit-content;
  padding: 12px 24px;
  border-radius: 50px;
  line-height: 17px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  color: white;
  background: transparent;
  cursor: pointer;
  &-primary {
    color: white;
    background-color:  #730cf5;
    filter: drop-shadow(0px 4px 7px rgba(0, 0, 0, 0.25));

    &[disabled] {
      color: rgba(255, 255, 255, 0.692);
      opacity: 0.3;
    }
  }
  &.btn-primary:hover {
  }
  &-secondary {
    background: #919191;

    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
  }
  &-alt {
    background: transparent;
    border: solid #585858 2px;
    color: #fff;
  }
  &-alt-two {
    background: #12444f;
    border: solid #12444f 2px;
    color: #fff;
  }
}
.btn-modal {
  width: fit-content;
}

/** modals */
ion-modal {
  --ion-background-color: transparent !important;
  --ion-toolbar-background: transparent !important;
  --ion-toolbar-border-color: transparent !important;

  @media (min-width: 640px) {
    left: -8vw;
  }

  .ion-page {
    justify-content: center;
    align-items: center;
  }
}
ion-modal {
  @media only screen and (min-width: 768px) and (min-height: 600px) {
    /* --border-radius: 2px; */
    --box-shadow: 0;
  }
}
// .swiper .swiper-slide img {
//   width: auto;
//   max-width: 100%;
//   height: 100dvh;
//   max-height: 100%;
//   object-fit: cover;
// }

//  .swiper .swiper-slide .image {
//    width: auto;
//    max-width: 100%;
//    max-height: 100%;
//  }

.swiper .swiper-slide .image-privew {
  width: auto;
  max-width: 100%;
  max-height: 100%;
  object-fit: fill;
}

/** scroll bard*/

::-webkit-scrollbar,
*::-webkit-scrollbar {
  display: none;
}
main::-webkit-scrollbar {
  display: none;
}

ion-content::part(scroll)::-webkit-scrollbar {
  display: none;
}

.overlay-image::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 10;
  background: transparent;
}

.text-error {
  color: #fc1313;
  font-size: 10px;
  line-height: 12px;
  width: 85%;
  margin: auto;
  margin-top: 5px;
}
.calendar {
  th {
    background: white;
    a {
      color: #585858;
    }
  }
  a {
    color: #dbdbdb;
  }
  .fc-button {
    background-color: transparent;
    border: none;
    @media (max-width: 820px) {
      font-size: 11px;
    }
  }
  .fc-button-primary:not(:disabled).fc-button-active {
    background-color: #ff002e;
    border: none;
    border-radius: 5px;
  }
  .fc-button-primary:disabled {
    background-color: transparent;
    border: 1px solid #585858;
  }
  .fc-button-primary:hover,
  .fc-list-event:hover td {
    background-color: #12444f;
    border: none;
  }
  .fc-highlight {
    background-color: #12444f;
  }
  .fc-daygrid-day.fc-day-today,
  .fc-timegrid-col.fc-day-today {
    background-color: #58585880;
  }
  .fc-toolbar-title {
    font-size: 1rem;
    font-weight: 700;
    @media (max-width: 820px) {
      display: none;
    }
  }
  .fc-h-event,
  .fc-v-event {
    background-color: #ff002e;
    border-color: #ff002e;
  }
  .fc-list-event-dot {
    border: 5px solid #ff002e;
  }
  .fc-popover {
    background: #000;
  }
  .fc-scrollgrid,
  td {
    border: none;
  }
  .fc-timegrid-axis-cushion {
    font-size: 14px;
    font-style: italic;
  }
  .fc-timegrid-slot {
    border-bottom: solid 1px #8d8d8d;
  }
  .fc-v-event .fc-event-main {
    white-space: nowrap;
    font-size: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    i {
      margin-left: 2px;
      @media (max-width: 820px) {
        font-size: 4px;
        font-weight: 900;
        margin-left: 0;
      }
    }
  }
  .fc-timegrid-slot-label-cushion {
    font-size: 12px;
    @media (max-width: 820px) {
      font-size: 10px;
    }
  }

  @media (max-width: 1400px) {
    .fc-col-header-cell-cushion {
      font-size: 12px;
      font-style: italic;
    }
  }
  @media (max-width: 820px) {
    height: calc(100% - 50px);
    .time-text,
    .fc-today-button {
      display: none;
    }
    .fc-toolbar.fc-header-toolbar {
      margin-bottom: 0.8rem;
    }
    .fc-col-header-cell-cushion {
      font-size: 9px;
    }
  }
}

ion-datetime.hour-calendar {
  --background: rgba(217, 217, 217, 0.9);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

ion-item.sc-ion-select-popover-md {
  --inner-border-width: 0;
  border: 1px solid;
}
ion-radio-group {
  width: 40px;
}
ion-item.sc-ion-select-popover-md {
  height: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
  font-size: 12px;
}

.popover-content {
  top: calc(287.375px + var(--offset-y, 0px)) !important;
  left: calc(566.844px + var(--offset-x, 0px)) !important;
  transform-origin: center top !important;
  width: 12% !important;
}
ion-textarea.wish-input .textarea-wrapper textarea.native-textarea {
  padding-left: 10px !important;
}
ion-fab-button.chat {
  margin-inline: 0px !important;
}
app-subscriptions-list {
  background-color: #000000;
}

ion-textarea.textarea-product .native-textarea {
  color: #1e1e1e !important;
}

.calendar-lust-list {
  .p-calendar {
    background-color: transparent;
    width: 100%;
  }

  .p-calendar .p-inputtext {
    background-color: transparent;
    width: 100%;
    border-color: #730cf5;
  }
  .p-calendar .p-inputtext:enabled:focus {
    box-shadow: none;
  }
}
.p-datepicker:not(.p-datepicker-inline) {
  background-color: black;
  border: solid 1px #730cf5;
}
.p-datepicker:not(.p-datepicker-inline) .p-datepicker-header {
  background-color: black;
  color: #8d8d8d;
}
.p-datepicker table td.p-datepicker-today > span {
  //border: solid 1px #;
  color: #730cf5;
  background-color: #8d8d8d;
}
.p-datepicker table td > span.p-highlight {
  border: solid 1px #730cf5;
  color: #8d8d8d;
  background-color: transparent;
}

//progress bar
.p-progressbar {
  border-radius: 0;
}
.p-progressbar .p-progressbar-label {
  display: none !important;
}
.p-progressbar {
  border: 0 none;
  height: 2px;
  background: rgba(159, 168, 218, 0.32);
  border-radius: 2px;
}
.p-progressbar .p-progressbar-value {
  border: 0 none;
  margin: 0;
  background: #9fa8da;
}
.p-progressbar .p-progressbar-label {
  color: #121212;
  line-height: 2px;
}
.p-progressbar .p-progressbar-value {
  background: #730cf5;
}
.p-progressbar-value-animate {
  transition: none !important;
}
.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  background: #730cf5;
}
.p-radiobutton .p-radiobutton-box {
  width: 15px;
  height: 15px;
  border: 2px solid #730cf5;
}
.p-radiobutton {
  width: 15px;
  height: 15px;
}
.p-rating .p-rating-icon,
.p-rating .p-rating-icon.pi-star-fill,
.p-rating:not(.p-disabled):not(.p-readonly) .p-rating-icon:hover {
  color: #fff;
  font-size: 24px;
}
.p-chips .p-chips-multiple-container .p-chips-token {
  font-size: 10px;
  background-color: #000;
  color: #fff;
  padding: 4px 4px;
}
.p-chips .p-chips-multiple-container .p-chips-input-token input {
  font-size: 10px;
}
.p-chips .p-chips-multiple-container:not(.p-disabled):hover {
  border-color: none !important;
}
.p-chips .p-chips-multiple-container:not(.p-disabled).p-focus {
  box-shadow: none;
}
.p-chips .p-chips-multiple-container {
  border-color: none !important;
  width: 100%;
}
.p-chips {
  width: 100%;
}

p-chips.ng-dirty.ng-invalid
  .p-chips
  .p-chips-multiple-container:not(.p-disabled).p-focus {
  box-shadow: none;
}

.swiper.swiper-new-publication .swiper-slide {
  margin: initial !important;
  min-width: initial !important;
  justify-content: flex-start !important;
}
